import React from 'react';
import { ChevronLeft, ChevronRight } from 'tabler-icons-react';
import { Box, Group, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { COLORS } from '../values/colors';

export const User = ({ username, name }) => {
  const theme = useMantineTheme();

  return (
    <Box
      sx={{
        paddingTop: theme.spacing.sm,
        borderTop: `1px solid ${theme.colors.gray[2]}`
      }}>
      <UnstyledButton
        sx={{
          display: 'block',
          width: '100%',
          padding: theme.spacing.xs,
          borderRadius: theme.radius.sm,
          color: theme.black,

          '&:hover': {
            backgroundColor: theme.colors.gray[0]
          }
        }}>
        <Group>
          <Box sx={{ flex: 1 }}>
            <Text size="sm" weight={500} color={COLORS.veryDarkPurple}>
              {name || 'First Name Full Name`'}
            </Text>
            <Text color="dimmed" size="xs">
              {username}
            </Text>
          </Box>

          {theme.dir === 'ltr' ? <ChevronRight size={18} /> : <ChevronLeft size={18} />}
        </Group>
      </UnstyledButton>
    </Box>
  );
};
