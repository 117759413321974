import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  Group,
  Modal,
  Paper,
  Stack,
  Text,
  TextInput
} from '@mantine/core';
import { useDispatch, useSelector } from 'react-redux';
import { changeUser, currentUser, getUser, patchWhole } from '../redux/user-slice';
import { DatePicker } from '@mantine/dates';
import { COLORS } from '../values/colors';
import httpClient from '../components/httpClient';
import dayjs from 'dayjs';

const defaultAvatar = require('../assets/img/img_avatar.png');

const ProfileGrid = ({ children, label, full }) => {
  return !full ? (
    <div className="basis-6/12 flex items-center mb-10">
      <p className="text-indigoDarkGray text-2xl grow">{label}</p>
      <div className="mx-10">{children}</div>
    </div>
  ) : (
      <div className="basis-6/12 flex items-center mb-10">
        <p className="text-indigoDarkGray text-2xl grow">{label}</p>
        <div className="mx-10">{children}</div>
      </div>
  );
};

const Profile = () => {
  const dispatch = useDispatch();
  const user = useSelector(currentUser);
  const [opened, setOpened] = useState(false);
  const [birthDate, setBirthDate] = useState(new Date(user?.externalUser?.birthDate) || null);
  const [education, setEducation] = useState(user?.externalUser?.education || null);
  const [occupation, setOccupation] = useState(user?.externalUser?.occupation || null);
  const [address, setAddress] = useState(user?.externalUser?.address || null);
  const [city, setCity] = useState(user?.externalUser?.city || null);
  const [country, setCountry] = useState(user?.externalUser?.country || null);
  const [email, setEmail] = useState(user?.externalUser?.email || null);
  const [phoneNumber, setPhoneNumber] = useState(user?.externalUser?.phoneNumber || null);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [avatar, setAvatar] = useState(user?.avatar || null);
  const [avatarBlob, setAvatarBlob] = useState(user?.avatar || null);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState('');
  const [firstName, setFirstName] = useState(user?.externalUser?.firstName || null);
  const [lastName, setLastName] = useState(user?.externalUser?.lastName || null);

  const handleSubmit = () => {
    dispatch(
      patchWhole({
        firstName,
        lastName,
        username: user?.username,
        birthDate,
        sex: user?.externalUser?.sex,
        education,
        occupation,
        address,
        city,
        country,
        email,
        phoneNumber
      })
    ).then(() => {
      updateAvatar();
    });
    setOpened(false);
  };

  useEffect(() => {
    dispatch(getUser({ username: user?.username }));
  }, []);

  const updateAvatar = () => {
    if (avatar !== user?.avatar) {
      const formData = new FormData();
      formData.append('avatar', avatarBlob);

      httpClient({
        method: 'POST',
        url: `/v1/users/change-avatar/${user?.username}`,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        data: formData
      })
        .then((response) => {
          dispatch(changeUser(response.data));
          console.log(response.data.avatar);
          setOpened(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleChangePassword = () => {
    httpClient({
      method: 'POST',
      url: '/v1/users/change-password',
      data: {
        username: user.username,
        password: oldPassword,
        newPassword: newPassword,
        newPasswordSecondTime: newPasswordConfirmation
      }
    }).then(() => {
      setIsPasswordModalOpen(false);
      setOldPassword('');
      setNewPassword('');
      setNewPasswordConfirmation('');
    });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setAvatarBlob(event.target.files[0]);
      setAvatar(URL.createObjectURL(event.target.files[0]));
    }
  };

  return (
    <Paper shadow="md" p="md" radius="xs" sx={{ minHeight: '85vh' }}>
      <Modal
        size="80rem"
        opened={opened}
        onClose={() => {
          setAvatar(user?.avatar);
          setOpened(false);
        }}
        centered>
        <Box mx="2.0rem">
          <p className="text-5xl text-indigoDarkGray font-bold">Editeaza profilul</p>
          <Divider mb="1.6rem" mt="1.6rem" />
        </Box>
        <label className="custom-file-upload">
          <div className="flex items-center ml-[1.46rem] my-[2rem]  cursor-pointer">
            <img
              src={avatar || defaultAvatar}
              alt="avatar"
              className="rounded-full w-[4rem] h-[4rem] mr-[4rem] object-cover"
            />
            <input
              type="file"
              name="image"
              accept="image/*"
              onChange={onImageChange}
              className="hidden"></input>
            <p className="text-indigoDarkGray text-[1.0rem]">Schimba imaginea de profil</p>
          </div>
        </label>
        <Grid style={{ marginLeft: '2rem' }}>
          <ProfileGrid label="Nume">
            <TextInput
              style={{ width: '25rem' }}
              value={lastName}
              onChange={(event) => setLastName(event.currentTarget.value)}
            />
          </ProfileGrid>
          <ProfileGrid label="Prenume">
            <TextInput
              style={{ width: '25rem' }}
              value={firstName}
              onChange={(event) => setFirstName(event.currentTarget.value)}
            />
          </ProfileGrid>
          <ProfileGrid label="Data nastere">
            <DatePicker
              style={{ width: '25rem' }}
              onChange={setBirthDate}
              value={birthDate}
              placeholder="Data nasterii"
              dropdownType="modal"
              locale="ro"
              maxDate={dayjs(new Date()).endOf('year').subtract(10, 'years').toDate()}
            />
          </ProfileGrid>

          <ProfileGrid label="Educatie">
            <TextInput
              style={{ width: '25rem' }}
              value={education}
              onChange={(event) => setEducation(event.currentTarget.value)}
            />
          </ProfileGrid>

          <ProfileGrid label="Profesie">
            <TextInput
              style={{ width: '25rem' }}
              value={occupation}
              onChange={(event) => setOccupation(event.currentTarget.value)}
            />
          </ProfileGrid>
          <ProfileGrid label="Numar telefon">
            <TextInput
              style={{ width: '25rem' }}
              value={phoneNumber}
              onChange={(event) => setPhoneNumber(event.currentTarget.value)}
            />
          </ProfileGrid>
          <Grid.Col span={12} style={{ padding: 0 }}>
            <ProfileGrid label="Email">
              <TextInput
                value={email}
                onChange={(event) => setEmail(event.currentTarget.value)}
                style={{ width: '62.5rem' }}
              />
            </ProfileGrid>
          </Grid.Col>
        </Grid>
        <Box mx="1.5rem" mr="2.2rem" mb="2rem">
          <p className="text-2xl text-indigoDarkGray font-bold">Adresa</p>
          <Divider my="1.5rem" />
        </Box>
        <Grid style={{ marginLeft: '2rem' }}>
          <Grid.Col span={12} style={{ padding: 0 }}>
            <ProfileGrid full label="Strada">
              <TextInput
                style={{ width: '62.5rem' }}
                value={address}
                onChange={(event) => setAddress(event.currentTarget.value)}
              />
            </ProfileGrid>
          </Grid.Col>
          <ProfileGrid label="Oras">
            <TextInput
              value={city}
              onChange={(event) => setCity(event.currentTarget.value)}
              style={{ width: '25rem' }}
            />
          </ProfileGrid>
          <ProfileGrid className label="Tara">
            <TextInput
              style={{ width: '25rem' }}
              value={country}
              onChange={(event) => setCountry(event.currentTarget.value)}
            />
          </ProfileGrid>
        </Grid>
        <Group position="center" mt="3rem" mb="5.0rem">
          <button
            className="w-[20rem] h-[4.8rem] mr-[5rem] bg-indigoDarkGray text-veryDarkWhite uppercase text-[1.5rem] font-semibold  border rounded-md"
            onClick={() => {
              setOpened(false);
            }}>
            Renunta
          </button>
          <Button
            onClick={handleSubmit}
            uppercase
            style={{ width: '20rem', height: '4.8rem', backgroundColor: COLORS.indigoDarkGray }}>
            Salveaza
          </Button>
        </Group>
      </Modal>
      <Text className="header" size="sm" weight="bold" mt="md">
        Profil
      </Text>
      <Divider my="xs" />
      <div className="flex">
        <div>
          <p className="text-indigoDarkGray text-[2.4rem] font-bold">
            {user.externalUser.firstName} {user.externalUser.lastName}
          </p>
          <p className="text-indigoDarkGray text-[1.2rem] mt-[1rem]">{user.username}</p>
        </div>
        <img
          className="rounded-full w-[5rem] h-[5rem] ml-[15rem] object-cover"
          src={user?.avatar || defaultAvatar}></img>
      </div>
      <div className="mt-[3rem] text-genericGray text-[1.3rem] child:mb-[1rem]">
        <p>
          Data nastere:{' '}
          {new Date(user.externalUser.birthDate).toLocaleDateString('ro-RO', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
        </p>
        <p>Educatie: {user?.externalUser?.education}</p>
        <p>Ocupatie: {user?.externalUser?.occupation}</p>
        <p>Tara: {user?.externalUser?.country}</p>
        <p>Oras: {user?.externalUser?.city}</p>
        <p>Adresa: {user?.externalUser?.address}</p>
        <p>Numar de telefon: {user?.externalUser?.phoneNumber}</p>
      </div>
      <Stack align="flex-start" mt={30}>
        <button
          className="bg-indigoDarkGray text-veryDarkWhite rounded-md w-[12rem] h-[4.0rem] text-[1.6rem] mb-[5rem]"
          onClick={() => setOpened(true)}>
          Editeaza
        </button>
        <p
          className="text-indigoDarkGray text-[1.4rem] hover:cursor-pointer font-bold"
          onClick={setIsPasswordModalOpen}>
          Schimbă parola
        </p>
      </Stack>
      <Modal
        size="80rem"
        opened={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
        centered>
        <div>
          <Box mx="2.0rem">
            <p className="text-5xl text-indigoDarkGray font-bold">Schimbare parola</p>
            <Divider mb="1.6rem" mt="1.6rem" />
            <p className="pt-[2rem]">Parola veche</p>
            <TextInput
              type="password"
              onChange={(val) => setOldPassword(val.currentTarget.value)}
            />
            <p className="pt-[2rem]">Parola noua</p>
            <TextInput
              type="password"
              onChange={(val) => setNewPassword(val.currentTarget.value)}
            />
            <p className="pt-[2rem]">Reintrodu parola noua</p>
            <TextInput
              type="password"
              onChange={(val) => setNewPasswordConfirmation(val.currentTarget.value)}
            />
            <Button
              onClick={() => {
                handleChangePassword();
              }}
              uppercase
              style={{
                width: '20rem',
                height: '4.8rem',
                backgroundColor: COLORS.indigoDarkGray,
                marginTop: '2rem'
              }}>
              salveaza
            </Button>
          </Box>
        </div>
      </Modal>
    </Paper>
  );
};

export default Profile;
