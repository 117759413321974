import React, { useEffect } from 'react';
import { Button, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import { EyeCheck, EyeOff } from 'tabler-icons-react';
import './Login.scss';
import {useForm, yupResolver} from '@mantine/form';
import { useCookies } from 'react-cookie';
import httpClient from '../../components/httpClient';
import { NavLink, useNavigate } from 'react-router-dom';
import { COLORS } from '../../values/colors';
import { addUser, removerUser } from '../../redux/user-slice';
import { useDispatch } from 'react-redux';
import { removeAllAssessments } from '../../redux/assessment-slice';
import { removeCookie } from '../../features/cookie';
import * as Yup from "yup";

const Login = () => {
  const schema = Yup.object().shape({
    email: Yup.string().email('Invalid email').required("E-mail-ul este obligatoriu"),
    password:  Yup.string().required("Parola este obligatorie"),
  })

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      email: '',
      password: ''
    }
  });
  const [, setTokenCookie] = useCookies(['token']);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginError, setLoginError] = React.useState(false);

  useEffect(() => {
    removeCookie('access_token');
    removeCookie('refresh_token');
    dispatch(removerUser());
    dispatch(removeAllAssessments());
  }, []);

  const submitLoginData = ({ email, password }) => {

    const loginFormData = new FormData();
    loginFormData.append('username', email);
    loginFormData.append('password', password);

    httpClient
      .post('secureLogin', loginFormData, {
        crossDomain: true,
        headers: {
          'Content-Type': 'application/form-data',
          'Access-Control-Allow-Origin': 'http://localhost:3000',
          Accept: 'application/json'
        }
      })
      .then((response) => {
        setTokenCookie('access_token', response.headers.access_token, {
          path: '/'
        });
        setTokenCookie('refresh_token', response.headers.refresh_token, {
          path: '/'
        });
        httpClient
          .get(`/v1/users/${email}`)
          .then((data) => {
            dispatch(addUser(data.data));
            navigate('/', { replace: true });
          })
          .catch((err) => console.error(err));
      })
      .catch(() => {
        setLoginError(true);
      });
  };

  return (
    <div className="pageContainer">
      <div className="leftSideContainer">
        <Text className="leftSideTitle" weight="bold" color={COLORS.veryDarkWhite} align="center">
          Evaluare și diagnostic gratuit
        </Text>
        <Text weight={600} color={COLORS.veryDarkWhite} align="center" className="leftSideSubtitle">
          Intră și fă-ți un test de autoevaluare pentru depresie, anxietate, stres
        </Text>
      </div>
      <div className="rightSideContainer">
        <div className="rightSideTextGroup">
          <Text align="center" weight="bold" className="rightSideTitle">
            Intră în cont
          </Text>
          <Group spacing="xs">
            <Text className="changePasswordText">Nu ai un cont?</Text>
            <NavLink
              to="/signup"
              style={{
                textDecoration: 'none'
              }}>
              <Text className="changePasswordText" weight={600} color={COLORS.indigoDarkGray}>
                Creează cont
              </Text>
            </NavLink>
          </Group>
        </div>
        <form onSubmit={form.onSubmit((values) => submitLoginData(values))}>
          <Text className="emailLabel">Email</Text>
          <TextInput
            placeholder="Email"
            size="md"
            radius="md"
            type="email"
            className="emailInput"
            {...form.getInputProps('email')}
          />
          <Text className="passwordLabel">Parolă</Text>
          <PasswordInput
            placeholder="Parolă"
            className="passwordInput"
            size="md"
            radius="md"
            {...form.getInputProps('password')}
            visibilityToggleIcon={({ reveal, size }) =>
              reveal ? <EyeOff size={size} /> : <EyeCheck size={size} />
            }
          />
          {loginError && ( <Text className="loginErrorText">Datele de inregistrare sunt incorecte sau e-mailul nu a fost confirmat.</Text>)}
          <Group spacing="xs" style={{ marginTop: '2rem' }}>
            <Text className="changePasswordText">
              Ai uitat parola?
            </Text>
            <Text className="changePasswordText cursor-pointer" color={COLORS.indigoDarkGray} weight={600}  onClick={() => navigate('/forgot-password', { replace: true })}>
              Schimbă parola
            </Text>
          </Group>
          <Button
            type="submit"
            className="loginButton"
            style={{ backgroundColor: COLORS.indigoDarkGray }}
            uppercase
            radius="md">
            Intră în cont
          </Button>
        </form>
      </div>
    </div>
  );
};

export default Login;
