import { Modal, Paper, Tabs, Text, TextInput} from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { useSortBy } from 'react-table/dist/react-table.development';
import httpClient from '../../components/httpClient';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { COLORS } from '../../values/colors';
import { useForm } from '@mantine/hooks';
import {currentUser, getUser} from "../../redux/user-slice";
import {useDispatch, useSelector} from "react-redux";
import defaultAvatar from "../../assets/img/img_avatar.png";
import { Notification } from '@mantine/core';
import {Check} from "tabler-icons-react";
import {showNotification} from "@mantine/notifications";
const UsersTable = ({ users, allowedRoles }) => {
  const normalizeRoles = (role) => {
    switch (role) {
      case 'ROLE_THERAPIST':
        return 'Psiholog';
        break;
      case 'ROLE_ADMIN':
        return 'Admin';
        break;
      case 'ROLE_USER':
        return 'Pacient';
        break;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nume',
        accessor: 'username',
        Cell: (props) => {
          const {
            username,
            avatar,
            externalUser: { firstName, lastName }
          } = props.cell.row.original;
          return (
            <div class="flex flex-row items-center my-[1rem] pl-[3rem]">
              <img class="w-[3.8rem] h-[3.8rem] rounded-full object-cover" src={avatar || defaultAvatar} alt="user-avatar" />
              <div class="ml-[1rem] text-genericGray">
                <p class="text-[1.2rem] font-medium">
                  {firstName} {lastName}
                </p>
                <p class="text-[1.0rem] font-light">{username}</p>
              </div>
            </div>
          );
        }
      },
      {
        Header: 'Access',
        accessor: 'roles',
        Cell: (props) => {
          const { roles } = props.cell.row.original;
          const userRoles = roles?.filter((role) => allowedRoles.includes(role.name));
          if (userRoles) {
            return (
              <div class="text-[1.4rem] text-veryDarkPurple">
                {userRoles.map((item) => (
                  <p>{normalizeRoles(item.name)}</p>
                ))}
              </div>
            );
          }
          return null;
        }
      }
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: users,
      initialState: {
        sortBy: [
          {id: 'createdAt',
            desc: true
          }
        ]
      }
    },
    useSortBy
  );

  return (
    <table className="w-full rounded mb-4" {...getTableProps()}>
      <thead className="bg-guyabano  h-[4rem] mb-[4rem]">
        {headerGroups.map((headerGroup) => (
          <tr
            className="text-left text-[1.4rem] text-veryDarkPurple"
            {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className="first:pl-[3rem]"
                {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr className="border-christmasSilver border-b" {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const Users = () => {
  const [users, setUsers] = useState([]);
  const [therapists, setTherapists] = useState([]);
  const user = useSelector(currentUser);
  const [modalOpen, setModalOpen] = useState(false);
  const [validatedResponse, setValidatedResponse] = useState(null);
  const dispatch = useDispatch();


  const form = useForm({
    initialValues: {
      username: ''
    },
    validate: {
      username: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email')
    }
  });

  useEffect(() => {

    dispatch(getUser({ username: user?.username }));
  }, []);


  useEffect(() => {
    httpClient.get('v1/users').then((data) => {
      console.log({ data });
      const filteredUsers = data?.data.filter(
        (user) => user?.roles.filter((role) => role.name === 'ROLE_USER').length
      );
      const filteredTherapists = data?.data.filter(
        (user) => user?.roles.filter((role) => role.name === 'ROLE_THERAPIST').length
      );
      setUsers(filteredUsers);
      setTherapists(filteredTherapists);
    });
  }, []);

  const onNewTherapistSubmit = ({ username }) => {
    httpClient({
      method: 'post',
      url: 'v1/therapist',
      data: username,
      headers: { 'Content-Type': 'text/plain' }
    })
        .then((res) => {
          if (res.data.status === "completed") {
            console.log({res})
            setValidatedResponse("Acest specialist are deja un cont activ!");
          } else {
            showNotification({
              title: 'Succes',
              message: 'Invitația a fost (re)trimisă cu succes!',
              color: 'orange',
              autoClose: 5000,
            })
            setValidatedResponse(null);
            setModalOpen(false);

          }
        })
        .catch((err) => {
          if (err.response.data.message === "Email already sent or the user was registered as patient.") {
            setValidatedResponse("Acest specialist are deja un cont activ sau este un pacient!");
          }
        });
  };

  return (
    <Paper shadow="md" p="md" radius="xs" style={{ minHeight: '75vh' }}>
      <Tabs variant="pills">
        <Tabs.Tab label="Pacienti">
          <UsersTable users={users} allowedRoles={['ROLE_USER']} />
        </Tabs.Tab>
        <Tabs.Tab label="Echipa">
          <UsersTable users={therapists} allowedRoles={['ROLE_THERAPIST', 'ROLE_ADMIN']} />
        </Tabs.Tab>
      </Tabs>
      <div className="fixed right-[5rem] top-[10rem]">
        <div className="flex items-center cursor-pointer" onClick={setModalOpen}>
          <FontAwesomeIcon
            color={COLORS.indigoDarkGray}
            icon={faPlus}
            fontSize="1.7rem"
            width={20}
          />
          <p className="text-[1.6rem] ml-[1rem] text-indigoDarkGray bold ">Adaugare specialist</p>
        </div>
      </div>
      <Modal size={700} opened={modalOpen} onClose={() => setModalOpen(false)}>
        <div className="mb-[4.0rem]">
          <p className="text-5xl text-indigoDarkGray font-bold">Adaugare specialist</p>
        </div>
        <form onSubmit={form.onSubmit((values) => onNewTherapistSubmit(values))}>
          <Text className="emailLabel">E-mail</Text>
          <TextInput
            placeholder="Email"
            required
            size="md"
            radius="md"
            type="email"
            className="emailInput"
            {...form.getInputProps('username')}
          />
          <p className="text-red text-[1.2rem] my-[1rem]">{validatedResponse}</p>
          <button
            type="submit"
            className="bg-genericBlue w-[12rem] h-[4rem] my-[4rem] rounded-md text-veryDarkWhite text-[1.5rem] uppercase">
            trimite
          </button>
        </form>
      </Modal>
    </Paper>
  );
};
