import { Paper, Text, Divider, Modal, List } from '@mantine/core';
import { Document, Font, Page, PDFDownloadLink, Text as PText, View } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import httpClient from '../components/httpClient';
import { currentUser, getUser } from '../redux/user-slice';
import { COLORS } from '../values/colors';
import Montserrat from '../assets/Montserrat-Regular.ttf';
import MontserratBold from '../assets/Montserrat-Bold.ttf';
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import RichTextEditor from '@mantine/rte';
import Html from 'react-pdf-html';

const RenderingDocument = ({ testRef, evaluationReport, titleRef, user, raportNote }) => {
  Font.register({
    family: 'Montserrat',
    format: 'truetype',
    fonts: [{ src: Montserrat }, { src: MontserratBold, fontWeight: 600 }]
  });
  return (
    <Document>
      <Page style={{ fontFamily: 'Montserrat', fontWeight: 500, padding: 20 }} size="A4">
        <PText
          style={{
            fontSize: 30,
            fontWeight: 600,
            color: COLORS.indigoDarkGray
          }}>
          {titleRef}
        </PText>
        <View style={{ display: 'flex', fontSize: 18, flexDirection: 'row', marginTop: 20 }}>
          <PText
            style={{
              backgroundColor: COLORS.indigoDarkGray,
              color: COLORS.veryDarkWhite,
              width: '50%'
            }}>
            Persoana evaluata
          </PText>
          <PText
            style={{
              backgroundColor: COLORS.genericGray,
              color: COLORS.veryDarkWhite,
              width: '50%'
            }}>
            {user?.externalUser?.firstName} {user?.externalUser?.lastName}, &nbsp; 
             {new Date().getFullYear() - new Date(user?.externalUser?.birthDate).getFullYear()} ani
          </PText>
        </View>
      </Page>
      {raportNote && <Page style={{ fontFamily: 'Montserrat', padding: 20 }}>
        <PText    style={{
            fontSize: 30,
            fontWeight: 600,
            color: COLORS.indigoDarkGray
          }}>Indicatii specialist</PText>
        <Html style={{fontSize: 10}}>{raportNote}</Html>
      </Page>
    } 
      <Page style={{ fontFamily: 'Montserrat', fontWeight: 500, padding: 20 }}>
        <PText style={{ fontSize: 12 }}>{testRef}</PText>
      </Page>
      <Page style={{ fontFamily: 'Montserrat', fontWeight: 500, padding: 20, fontSize: 10 }}>
        <View>
          {evaluationReport?.factors.map((factor) => (
            <View key={factor.id}>
              <View
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  paddingBottom: 30,
                  alignItems: 'start'
                }}>
                <View style={{ width: '33%' }}>
                  <PText style={{ fontSize: 12, fontWeight: 600 }}>{factor.lowerPoleTitle}</PText>
                  <PText>{factor.lowerPoleEvaluationExtendedDescription}</PText>
                  <PText>{factor.lowerPoleExtremeText}</PText>
                  <View style={{ paddingTop: 20 }}>
                    {factor.lowerPoleAdvantages && (
                      <View>
                        <PText style={{ fontSize: 12, fontWeight: 600 }}>Avantaje:</PText>
                        <PText>{factor.lowerPoleAdvantages}</PText>
                      </View>
                    )}
                    {factor.lowerPoleRisks && (
                      <View>
                        <PText style={{ fontSize: 12, fontWeight: 600 }}>Riscuri:</PText>
                        <PText>{factor.lowerPoleRisks}</PText>
                      </View>
                    )}
                  </View>
                </View>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  {factor?.questionnaireClasses?.map((qclass, index) => (
                    <View
                      style={{
                        backgroundColor:
                          index + 1 === factor.questionnaireClass
                            ? COLORS.genericBlue
                            : COLORS.indigoDarkGray,
                        color: COLORS.veryDarkWhite,
                        width: 15,
                        height: 15
                      }}
                      className={`${
                        index + 1 === factor.questionnaireClass
                          ? 'bg-genericBlue'
                          : 'bg-indigoDarkGray'
                      } text-veryDarkWhite w-[2rem]`}>
                      <PText style={{ textAlign: 'center', fontSize: 10 }}>{qclass.value}</PText>
                    </View>
                  ))}
                </View>
                <View style={{ width: '33%' }}>
                  <PText style={{ fontSize: 12, fontWeight: 600 }}>
                    {factor.superiorPoleTitle}
                  </PText>
                  <PText>{factor.superiorPoleEvaluationExtendedDescription}</PText>
                  <PText>{factor.superiorPoleExtremeText}</PText>
                  <View style={{ paddingTop: 20 }}>
                    {factor.superiorPoleAdvantages && (
                      <View>
                        <PText style={{ fontSize: 12, fontWeight: 600 }}>Avantaje:</PText>
                        <PText>{factor.superiorPoleAdvantages}</PText>
                      </View>
                    )}
                    {factor.superiorPoleRisks && (
                      <View>
                        <PText style={{ fontSize: 12, fontWeight: 600 }}>Riscuri:</PText>
                        <PText>{factor.superiorPoleRisks}</PText>
                      </View>
                    )}
                  </View>
                </View>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export const Raport = ({evaluationReport}) => {
  return (
    <div>
         {evaluationReport?.factors.map((factor) => (
          <div key={factor.id}>
            <div className="flex justify-between pb-[3rem] items-start">
              <div className="w-[33%]">
                <p className="font-bold text-[1.6rem]">{factor.lowerPoleTitle}</p>
                <span className="w-[2rem] text-[1.4rem]">
                  {factor.lowerPoleEvaluationExtendedDescription}
                </span>
                <span className="w-[2rem] text-[1.4rem]">{factor.lowerPoleExtremeText}</span>
                <div className="text-[1.4rem] pt-[2rem]">
                  {factor.lowerPoleAdvantages && (
                    <div>
                      <p className="font-bold text-[1.6rem]">Avantaje:</p>
                      <span>{factor.lowerPoleAdvantages}</span>
                    </div>
                  )}
                  {factor.lowerPoleRisks && (
                    <div>
                      <p className="font-bold text-[1.6rem]">Riscuri:</p>
                      <span>{factor.lowerPoleRisks}</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex">
                {factor?.questionnaireClasses?.map((qclass, index) => (
                  <div
                    className={`${
                      index + 1 === factor.questionnaireClass
                        ? 'bg-genericBlue'
                        : 'bg-indigoDarkGray'
                    } text-veryDarkWhite w-[2rem]`}>
                    <p class="text-center text-[1.4rem]">{qclass.value}</p>
                  </div>
                ))}
              </div>
              <div className="w-[33%]">
                <p className="font-bold text-[1.6rem]">{factor.superiorPoleTitle}</p>
                <span className="w-[2rem] text-[1.4rem]">
                  {factor.superiorPoleEvaluationExtendedDescription}
                </span>
                <span className="w-[2rem] text-[1.4rem]">{factor.superiorPoleExtremeText}</span>
                <div className="text-[1.4rem] pt-[2rem]">
                  {factor.superiorPoleAdvantages && (
                    <div>
                      <p className="font-bold text-[1.6rem]">Avantaje:</p>
                      <span>{factor.superiorPoleAdvantages}</span>
                    </div>
                  )}
                  {factor.superiorPoleRisks && (
                    <div>
                      <p className="font-bold text-[1.6rem]">Riscuri:</p>
                      <span>{factor.superiorPoleRisks}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))} 
        </div>
  )
}

export const AssessmentRaport = () => {
  const user = useSelector(currentUser);
  const [completedQuestionnaires, setcompletedQuestionnaires] = useState([]);
  const [opened, setOpened] = useState(false);
  const [evaluationReport, setEvaluationReport] = useState();
  const [domNode, setDomNode] = useState(null);
  const [titleNode, setTitleNode] = useState(null);
  const [raportNote, setRaportNote] = useState(null);
  const dispatch = useDispatch();

  const onTitleRefChange = useCallback((node) => {
    if (node !== null) {
      setTitleNode(node.innerText);
    }
  }, []);

  const onRefChange = useCallback((node) => {
    if (node !== null) {
      setDomNode(node.innerText);
    }
  }, []);

  useEffect(() => {
    dispatch(getUser({username: user?.username}))
  }, [])

  useEffect(() => {
    if (user) {
      httpClient
        .get(`v1/external/getExternalUserDetails`, {
          headers: {
            apiKEY: '5F9405A4-890A-4751-B809-AF6BF1909C20'
          },
          params: {
            externalUserId: user?.externalUser?.id
          }
        })
        .then((response) => {
          setcompletedQuestionnaires(response.data.completedQuestionnaires);
        })
        .catch((err) => console.warn(err.message));
    }
  }, []);

  const handleRaportGeneration = (event) => {
    httpClient
      .get(`v1/external/getEvaluation`, {
        headers: {
          apiKEY: '5F9405A4-890A-4751-B809-AF6BF1909C20'
        },
        params: {
          accessKey: event
        }
      })
      .then((response) => {
        setEvaluationReport(response.data.raport);
        const currentEvaluationNote = user?.evaluations?.find(item => item.accessKey === event);
        setRaportNote(currentEvaluationNote?.note?.text)
        setOpened(true);
      })
      .catch((err) => console.warn(err.message));

    //   setevaluationReport(open);
  };

  return (
    <Paper shadow="md" p="md" radius="xs" sx={{ minHeight: '65vh' }}>
      <Text color={COLORS.indigoDarkGray} size="sm" weight="bold">
        Activitate
      </Text>
      <Divider my="xs" />

      <List
        size="md"
        icon={
          <FontAwesomeIcon color={COLORS.veryDarkPurple} icon={faFile} fontSize={17} width={20} />
        }>
        {completedQuestionnaires.map((evaluation) => (
          <List.Item>
            <div
              onClick={() => handleRaportGeneration(evaluation.accessKey)}
              key={evaluation.accessKey}
              className="flex text-[1.4rem] text-veryDarkPurple hover:cursor-pointer hover:text-indigoDarkGray">
              <p className="mr-[0.5rem]">{evaluation.name}</p>
              <p className="mr-[0.5rem]">-</p>
              <p>{new Date(evaluation.startDate).toLocaleString()}</p>
            </div>
          </List.Item>
        ))}
      </List>
      <Modal size={900} opened={opened} onClose={() => setOpened(false)}>
        <PDFDownloadLink
          document={
            <RenderingDocument
              testRef={domNode}
              titleRef={titleNode}
              evaluationReport={evaluationReport}
              raportNote={raportNote}
              user={user}
            />
          }
          fileName="test.pdf">
          {({ blob, url, loading, error }) =>
            loading ? (
              'Loading document...'
            ) : (
              <button class="bg-genericBlue text-veryDarkWhite h-[4rem] w-[20rem] text-[1.4rem] rounded-lg mb-[2rem]">
                Descarcare raport
              </button>
            )
          }
        </PDFDownloadLink>
        <RichTextEditor readOnly value={raportNote} title="Indicatii specialist"/>
        <div
          ref={onTitleRefChange}
          dangerouslySetInnerHTML={{
            __html: evaluationReport?.questionnaire?.webTitle
          }}
          className="text-[3.0rem] font-bold text-indigoDarkGray"
        />
        <div className="flex text-[1.8rem] mb-[2rem]">
          <p className="bg-indigoDarkGray text-veryDarkWhite w-[50%]">&nbsp;Persoana evaluata</p>
          <span className="w-[50%] bg-genericGray text-veryDarkWhite">
            &nbsp;
            {user?.externalUser?.firstName} {user?.externalUser?.lastName},{' '}
            {new Date().getFullYear() - new Date(user?.externalUser?.birthDate).getFullYear()}{' '}
            ani
          </span>
        </div>
        {
          <div
            ref={onRefChange}
            className="text-[1.2rem]"
            dangerouslySetInnerHTML={{
              __html: evaluationReport?.questionnaire?.webDescription
            }}
          />
        }
      <Raport evaluationReport={evaluationReport}/>
      </Modal>
    </Paper>
  );
};
