import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import httpClient from '../../components/httpClient';
import verifyEmail from "../../assets/img/verifyEmail.jpeg";

const UserVerification = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [error, setError] = React.useState("");

  useEffect(() => {
    httpClient.get(`/v1/users/verify/${token}`).then((r) => {
      console.log(r);
      // navigate('/email-confirmation', { state: { email: r.data.username } });
    })
        .catch((e) => {
          setError(e.response.data.message);
        });
  }, []);
  return <div>
    <div className="flex items-center justify-center  text-center h-screen">
      <div className="flex max-w-[61rem] flex-col justify-center items-center">
        <div className="max-w-[31rem]">
          <h1 className="text-[2.4rem] font-bold text-veryDarkPurple mb-[2.2rem]">
            Verificare adresa de e-mail
          </h1>
        </div>
        <img className="max-w-[50%]" alt="verify-email-image" src={verifyEmail} />
        <h5 className="text-[1.8rem] text-veryDarkPurple mt-[6.6rem] m-[6rem]">
          {error ? error : " E-mailul a fost confirmat cu success. Multumim!"}
        </h5>
        <button
            className="w-[32.7rem] h-[4.8rem] bg-indigoDarkGray text-veryDarkWhite font-bold text-[1.2rem] rounded-[0.8rem]"
            onClick={() => navigate('/login')}>
          Intră în cont
        </button>
      </div>
    </div>
    );
  </div>;
};

export default UserVerification;
