import React, {useEffect, useState} from 'react';
import OnboardingLeftPanel from '../components/onboarding/OnboardingLeftPanel';
import { Button, Checkbox, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import OnboardingContainer from '../components/onboarding/OnboardingContainer';
import OnboardingRightContainer from '../components/onboarding/OnboardingRightContainer';
import { EyeCheck, EyeOff } from 'tabler-icons-react';
import {useForm, yupResolver} from '@mantine/form';
import './onboarding/Login.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import httpClient from '../components/httpClient';
import { removeCookie } from '../features/cookie';
import { COLORS } from '../values/colors';
import { removerUser } from '../redux/user-slice';
import { useDispatch } from 'react-redux';
import { removeAllAssessments } from '../redux/assessment-slice';
import * as Yup from "yup";

const SignUp = () => {
  const schema = Yup.object().shape({
    username: Yup.string().email('Formatul de e-mail este invalid').required('E-mail-ul este obligatoriu'),
    password:  Yup.string().min(6, "Cel putin 6 caractere sunt necesare").required('Parola este obligatorie'),
    firstName: Yup.string().required('Prenumele este obligatoriu'),
    lastName: Yup.string().required('Numele este obligatoriu'),
  })

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      username: '',
      password: '',
      firstName: '',
      lastName: ''
    }
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [displayErrorText, setDisplayErrorText] = useState('');



  const doSignUp = ({ username, password, firstName, lastName }) => {
    removeCookie('access_token');
    removeCookie('refresh_token');
    dispatch(removerUser());
    dispatch(removeAllAssessments());
    setDisplayErrorText(null);
    httpClient
      .post('v1/auth/signup', {
        username: username,
        password: password,
        firstName: firstName,
        lastName: lastName
      })
      .then((res) => {

        navigate('/email-confirmation', { state: { email: username } });
      }).catch((e) => {
      console.error(e.response.data)
      if (e.response.data.message === "Email already taken") {
        setDisplayErrorText('Emailul este deja folosit. Te rugam sa incerci alt email.')
      }
    })
  };

  return (
    <OnboardingContainer>
      <OnboardingLeftPanel />
      <OnboardingRightContainer>
        <div className="rightSideTextGroup">
          <Text align="center" weight="bold" className="rightSideTitle">
            Creeaza cont
          </Text>
          <Group spacing="xs">
            <Text className="changePasswordText">Ai deja un cont?</Text>
            <NavLink
              to="/login"
              style={{
                textDecoration: 'none'
              }}>
              <Text className="changePasswordText" weight={600} color={COLORS.indigoDarkGray}>
                Log In
              </Text>
            </NavLink>
          </Group>
        </div>
        <form onSubmit={form.onSubmit((values) => doSignUp(values))}>
          <Text className="emailLabel">Nume</Text>
          <TextInput
            placeholder="Nume"
            size="md"
            radius="md"
            className="emailInput"
            {...form.getInputProps('lastName')}
          />
          <Text className="passwordLabel">Prenume</Text>
          <TextInput
            className="emailInput"
            placeholder="Prenume"
            size="md"
            radius="md"
            {...form.getInputProps('firstName')}
          />
          <Text className="passwordLabel">Email</Text>
          <TextInput
            placeholder="Email"
            size="md"
            radius="md"
            className="emailInput"
            {...form.getInputProps('username')}
          />
          <Text className="passwordLabel">Parolă</Text>
          <PasswordInput
            placeholder="Parolă (minim 6 caractere)"
            className="emailInput"
            size="md"
            radius="md"
            {...form.getInputProps('password')}
            visibilityToggleIcon={({ reveal, size }) =>
              reveal ? <EyeOff size={size} /> : <EyeCheck size={size} />
            }
          />
          <Checkbox
            styles={{
              label: {
                color: COLORS.veryDarkPurple,
                fontSize: '1.2rem',
                fontWeight: 500,
                width: '28rem'
              }
            }}
            label="Dorești să primești notificări cu privire la servicii și alte informații de la depreHUB"
            mt="lg"
            mb="lg"
          />
          <p className="text-red text-[1.2rem]">{displayErrorText}</p>
          <Button
            type="submit"
            className="loginButton"
            style={{ backgroundColor: COLORS.indigoDarkGray }}
            uppercase
            radius="md">
            Înregistrează-te
          </Button>
        </form>
        <Text
          align="center"
          style={{ width: '33rem', fontSize: '1.2rem' }}
          mt="lg"
          color={COLORS.veryDarkPurple}>
          Continuând, sunteți de acord să acceptați Politica de confidențialitate și Termenii și
          condițiile.
        </Text>
      </OnboardingRightContainer>
    </OnboardingContainer>
  );
};

export default SignUp;
