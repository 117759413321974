import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import httpClient from '../components/httpClient';

export const getUser = createAsyncThunk('/user/getUser', async ({ username }) => {
  const response = await httpClient({
    method: 'get',
    url: `v1/users/${username}`
  });
  return { data: response.data };
});

export const patchWhole = createAsyncThunk(
  '/user/patchWhole',
  async ({
    username,
    sex,
    birthDate,
    occupation,
    education,
    address,
    city,
    country,
    phoneNumber,
      firstName,
      lastName,
  }) => {
    const response = await httpClient({
      method: 'patch',
      url: `v1/users/patchWhole/${username}`,
      data: {
        email: username,
        sex,
        birthDate,
        occupation,
        education,
        address,
        city,
        country,
        phoneNumber,
        firstName,
        lastName,
      }
    });
    return { data: response.data };
  }
);

const initialState = {
  currentUser: {
    id: null,
    username: null,
    roles: [{ id: null, name: null }],
    externalUser: {
      email: null,
      firstName: null,
      lastName: null,
      sex: null,
      birthDate: null,
      groupID: null,
      id: null
    },
    therapeut: null,
    therapeuts: null,
    updatedAt: null,
    createdAt: null
  },
  status: ''
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (state, action) => {
      console.log(state, action.payload);
      state.currentUser = action.payload;
    },
    removerUser: (state, action) => {
      state.currentUser = null;
    },
    changeUser: (state, action) => {
      state.currentUser = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.fulfilled, (state, { payload: { data } }) => {
      state.currentUser = data;
      state.status = 'loaded';
    });
    builder.addCase(patchWhole.fulfilled, (state, { payload: { data } }) => {
      state.currentUser = data;
      state.status = 'loaded';
    });
  }
});

export const currentUser = (state) => state.user.currentUser;
export const { addUser, removerUser, changeUser } = userSlice.actions;
export default userSlice.reducer;
