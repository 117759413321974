import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import verifyEmail from '../../assets/img/verifyEmail.jpeg';

const EmailPending = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const email = state?.email;

  console.log(state);
  return (
    <div className="flex items-center justify-center  text-center h-screen">
      <div className="flex max-w-[61rem] flex-col justify-center items-center">
        <div className="max-w-[31rem]">
          <h1 className="text-[2.4rem] font-bold text-veryDarkPurple mb-[2.2rem]">
            Verifică adresa de e-mail
          </h1>
          <p className="text-[1.3rem] text-veryDarkPurple">
            Trebuie să verifici adresa de e-mail pentru a finaliza înregistrarea
          </p>
        </div>
        <img className="max-w-[50%]" alt="verify-email-image" src={verifyEmail} />
        <h5 className="text-[1.8rem] text-veryDarkPurple mt-[6.6rem] m-[6rem]">
          Un email a fost trimis către adresa {email} conținând un link de verificare. Dacă nu
          primești email-ul în câteva minute, verifică folderul de Spam.
        </h5>
        <button
          className="w-[32.7rem] h-[4.8rem] bg-indigoDarkGray text-veryDarkWhite font-bold text-[1.2rem] rounded-[0.8rem]"
          onClick={() => navigate('/login')}>
          Intră în cont
        </button>
      </div>
    </div>
  );
};

export default EmailPending;
