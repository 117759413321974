import { Paper, Tabs } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { useSortBy } from 'react-table/dist/react-table.development';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currentUser } from '../../redux/user-slice';
import defaultAvatar from "../../assets/img/img_avatar.png";

const UsersTable = ({ users, allowedRoles }) => {
  const navigate = useNavigate();

  const navigateToUserInfo = (id) => {
    navigate(`/therapist/users/${id}`);
  };

  const normalizeRoles = (role) => {
    switch (role) {
      case 'ROLE_THERAPIST':
        return 'Psiholog';
        break;
      case 'ROLE_ADMIN':
        return 'Admin';
        break;
      case 'ROLE_USER':
        return 'Pacient';
        break;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Nume',
        accessor: 'username',
        Cell: (props) => {
          const {
            username,
            id,
            avatar,
            externalUser: { firstName, lastName }
          } = props.cell.row.original;
          return (
            <div
              class="flex flex-row items-center my-[1rem] pl-[3rem]"
              onClick={() => navigateToUserInfo(id)}>
              <img class="w-[3.8rem] h-[3.8rem] rounded-full object-cover" src={avatar || defaultAvatar} alt="patients-avatar" />
              <div class="ml-[1rem] text-genericGray">
                <p class="text-[1.2rem] font-medium">
                  {firstName} {lastName}
                </p>
                <p class="text-[1.0rem] font-light">{username}</p>
              </div>
            </div>
          );
        }
      },
      {
        Header: 'Access',
        accessor: 'roles',
        Cell: (props) => {
          const { roles } = props.cell.row.original;
          const userRoles = roles?.filter((role) => allowedRoles?.includes(role.name));
          if (userRoles) {
            return (
              <div class="text-[1.4rem] text-veryDarkPurple">
                {userRoles?.map((item) => (
                  <p>{normalizeRoles(item.name)}</p>
                ))}
              </div>
            );
          }
          return null;
        }
      }
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data: users
    },
    useSortBy
  );

  return (
    <table class="w-full rounded mb-4" {...getTableProps()}>
      <thead class="bg-guyabano  h-[4rem] mb-[4rem]">
        {headerGroups.map((headerGroup) => (
          <tr
            class="text-left text-[1.4rem] text-veryDarkPurple"
            {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th class="first:pl-[3rem]" {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr class="border-christmasSilver border-b" {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
const TherapistUsers = () => {
  const [users, setUsers] = useState([]);
  const user = useSelector(currentUser);

  useEffect(() => {
    //TODO API CALL TO ADD USER
    if (user?.therapist?.userList) {
      setUsers(user?.therapist?.userList);
    }
  }, [user]);

  return (
    <Paper shadow="md" p="md" radius="xs" style={{ minHeight: '75vh' }}>
      <Tabs variant="pills">
        <Tabs.Tab label="Pacienti">
          <UsersTable users={users} allowedRoles={['ROLE_USER']} />
        </Tabs.Tab>
      </Tabs>
    </Paper>
  );
};

export default TherapistUsers;
