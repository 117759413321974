import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, Group, Paper, Radio, RadioGroup, Space, Text } from '@mantine/core';
import { useNavigate, useParams } from 'react-router-dom';
import {
  answerEvaluation,
  cancelAssessment,
  currentAssessment,
  getEvaluation,
  removeAssessment,
  setHasStarted,
  startEvaluation
} from '../redux/assessment-slice';
import { useDispatch, useSelector } from 'react-redux';
import { currentUser } from '../redux/user-slice';
import { COLORS } from '../values/colors';
import './Assessment.scss';
import { FormattedText } from '../components/FormattedText';
import httpClient from '../components/httpClient';

const Assessment = () => {
  const { id, versionID } = useParams();
  const user = useSelector(currentUser);
  const dispatch = useDispatch();
  const assessment = useSelector(currentAssessment);
  const hasStarted = useSelector((state) => state?.assessments?.startedEvaluations[id]?.hasStarted);
  const curr = useSelector((state) => state?.assessments?.assessmentsList.data).find(
    (item) => item.id === Number(id)
  );
  const [answer, setAnswer] = useState('');
  const [assessmentHasLoaded, setAssessmentHasLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!assessment[id]) {
      dispatch(
        startEvaluation({
          questionnaireVersionID: parseInt(versionID),
          personID: user.externalUser.id,
          username: user?.username,
          pageID: Number(id)
        })
      ).then(() => setAssessmentHasLoaded(true));
    }
  }, [currentAssessment]);

  useEffect(() => {
    if (assessmentHasLoaded) {
      dispatch(getEvaluation({ accessKey: assessment[id]?.accessKey, pageID: id })).then(() =>
        setAssessmentHasLoaded(false)
      );
    }
  }, [assessmentHasLoaded]);

  const handleAnswer = (event) => {
    const selectedAssessment = assessment[id]?.data?.question?.responseOptions.find(
      (item) => event === item.text
    );
    setAnswer(selectedAssessment.text);
    if (selectedAssessment.text !== answer)
      dispatch(
        answerEvaluation({
          responseOptionId: selectedAssessment.id,
          accessKey: assessment[id].accessKey,
          questionId: assessment[id]?.data?.question.id,
          pageID: id
        })
      ).then(() => {
        setAnswer('');
        dispatch(
          getEvaluation({
            accessKey: assessment[id].accessKey,
            pageID: id
          })
        ).then((response) => {
          const currentQuestion = response?.payload?.data?.question;
          if (!currentQuestion) {
            httpClient({
              method: 'post',
              url: 'v1/external/completeEvaluation',
              data: {
                id: assessment[id]?.id
              }
            });
            dispatch(removeAssessment({ pageID: id, id: assessment[id].id })).then(() => {
              navigate('/assessment', { replace: true });
            });
          }
        });
      });
  };

  const handleCancelation = () => {
    console.log({ assessment });
    dispatch(cancelAssessment({ pageID: id, id: assessment[id]?.id })).then(() => {
      navigate('/assessment', { replace: true });
    });
  };

  return (
    <Paper shadow="md" p="md" radius="xs">
      <Text className="header" size="sm" weight="bold" mt="md">
        {curr?.externalAssessments?.name}: {curr?.externalAssessments?.shortDescription}
      </Text>
      <Divider my="xs" />
      <Space h="md" />
      {!hasStarted ? (
        <Text mx="auto" mb={75} className="instructions">
          {FormattedText(assessment[id]?.data?.instructions)}
        </Text>
      ) : null}
      {hasStarted ? (
        <Box className="mainContainerQuestions">
          <Box className="secondContainerQuestions">
            <Text className="question">
              {assessment[id]?.data.question?.index}/{assessment[id]?.data.question?.count}:{' '}
              {FormattedText(assessment[id]?.data?.question?.text)}
            </Text>
            <RadioGroup
              orientation="vertical"
              required
              value={answer}
              onChange={handleAnswer}
              spacing="sm"
              sx={{ fontSize: '2.5rem' }}
              pb={46}
              styles={{
                label: {
                  color: COLORS.indigoDarkGray,
                  fontSize: '1.6rem'
                },
                radio: { borderRadius: 3 }
              }}>
              {assessment[id]?.data?.question?.responseOptions.map((response) => (
                <Radio
                  style={{ minWidth: 400, fontWeight: 500 }}
                  align="center"
                  value={response.text}
                  label={response.text}
                  key={response.id}
                />
              ))}
            </RadioGroup>
          </Box>
        </Box>
      ) : null}
      <p class="text-indigoDarkGray font-bold text-[1.2rem] m-[25px]">
        Alege un răspuns, pagina se va muta la următoarea întrebare automat
      </p>
      <Group position="apart" m={25}>
        <Button
          uppercase
          variant="outline"
          style={{ color: COLORS.indigoDarkGray, borderColor: COLORS.indigoDarkGray }}
          onClick={handleCancelation}
          sx={{ width: '32rem', height: '4rem' }}>
          renunta si inchide fereastra
        </Button>
        {!hasStarted ? (
          <Button
            uppercase
            style={{ backgroundColor: COLORS.indigoDarkGray }}
            onClick={() => dispatch(setHasStarted({ pageID: id, hasStarted: true }))}
            sx={{ width: '32rem' }}>
            incepe evaluarea
          </Button>
        ) : null}
      </Group>
    </Paper>
  );
};

export default Assessment;
