import axios from 'axios';
import { parseCookies, removeCookie } from '../features/cookie';
import { config } from '@fortawesome/fontawesome-svg-core';
import { serialize } from 'cookie';

const baseURL = process.env.REACT_APP_BASE_PATH || 'http://localhost:8080/api/';


const httpClient = axios.create({
  baseURL: baseURL
});

httpClient.interceptors.request.use((config) => {
  // eslint-disable-next-line camelcase
  const { access_token } = parseCookies(config);
  // eslint-disable-next-line camelcase
  if (!access_token) return config;

  const configCopy = { ...config };

  if (configCopy.headers) {
    // eslint-disable-next-line camelcase
    configCopy.headers.Authorization = `Bearer ${access_token}`;
  }

  return configCopy;
});

httpClient.interceptors.response.use(
  (success) => success,
  (err) => {
    const configCopy = { ...err.config };
    // eslint-disable-next-line camelcase
    const { refresh_token } = parseCookies(config);
    if (err.request && err.request.status === 403) {
      return axios
        .get(`${baseURL}v1/token/refresh`, {
          headers: {
            // eslint-disable-next-line camelcase
            Authorization: `Bearer ${refresh_token}`
          }
        })
        .then((response) => {
          if (configCopy.headers) {
            configCopy.headers.Authorization = `Bearer ${response.data.access_token}`;
            const newAccessToken = serialize('access_token', response.data.access_token);
            document.cookie = newAccessToken;
          }
          return axios(configCopy);
        })
        .catch((err) => {
          if (err.request && err.request.status === 401) {
            removeCookie('access_token');
            removeCookie('refresh_token');
            // window.location.replace('/login');
          }
        });
    }
    if (err.request && err.request.status === 401) {
      removeCookie('access_token');
      removeCookie('refresh_token');
      // window.location.replace('/login');
    }

    return Promise.reject(err);
  }
);

export default httpClient;
