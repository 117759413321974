import React, { useState } from 'react';
import { Divider, Modal, Paper, Text } from '@mantine/core';
import httpClient from '../components/httpClient';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { currentUser } from '../redux/user-slice';
import defaultAvatar from "../assets/img/img_avatar.png";

const Therapists = () => {
  const [opened, setOpened] = useState(false);
  const [therapists, setTherapists] = useState([]);
  const [currentTherapist, setCurrentTherapist] = useState();
  const user = useSelector(currentUser);

  useEffect(() => {
    httpClient
      .get('v1/therapists')
      .then((res) => setTherapists(res.data))
      .catch((err) => console.err(err));
  }, []);

  const handleSubmit = () => {
    if (!currentTherapist) {
      return null;
    }

    httpClient({
      url: `v1/therapists/addUser/${currentTherapist.id}`,
      method: 'POST',
      data: {
        username: user?.externalUser?.email
      }
    }).then((res) => {
      setOpened(false);
    });
  };

  const handleTherapistPick = (therapist) => {
    console.log(therapist)
    setCurrentTherapist(therapist);
    setOpened(true);
  };

  return (
    <Paper shadow="md" p="md" radius="xs" sx={{ minHeight: '85vh' }}>
      <Text className="header" size="sm" weight="bold" mt="md">
        Lista Terapeuti
      </Text>
      <Divider my="xs" />
      <div className="flex flex-wrap">
        {therapists.map((therapist) => (
          <div
              hidden={therapist?.user.externalUser?.email === user.username}
            key={therapist.id}
            class="w-[18rem] h-[28rem] border rounded-md border-lightGray ml-[4rem] mt-[3.0rem] mb-[2rem]">
            <div className="px-[1.5rem]">
              <div className="flex flex-col justify-center items-center mt-[2.0rem] mb-[0.6rem]">
                <img
                  src={therapist?.user?.avatar || defaultAvatar}
                  className="w-[44px] h-[44px] rounded-full border border-lightGray mb-[1rem]"
                  alt="therapist-avatar"
                />
                <p className="text-indigoDarkGray text-[1.4rem] font-bold">
                  {therapist?.user?.externalUser?.firstName}{' '}
                  {therapist?.user?.externalUser?.lastName}
                </p>
                <p className="text-indigoDarkGray text-[0.9rem]">{therapist.title}</p>
              </div>
              <div className="mt-[2.4rem] flex flex-col align-baseline">
                <p className="text-indigoDarkGray text-[1.0rem]">Specializari</p>
                <div className="mt-[0.35rem] border-solid border-lightGray border-b mb-[1.0rem]" />
                <ol className="list-decimal pl-[1.2rem] text-indigoDarkGray">
                  <li>{therapist.firstSpeciality}</li>
                  <li>{therapist.secondSpeciality}</li>
                  <li>{therapist.thirdSpeciality}</li>
                </ol>
                <button
                  onClick={() => handleTherapistPick(therapist)}
                  className="bg-indigoDarkGray w-[12rem] h-[2.8rem] text-veryDarkWhite uppercase border rounded-lg mt-[3.5rem] mx-auto">
                  alege
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal centered size={750} opened={opened} onClose={() => setOpened(false)}>
        {
          <div className="mr-[7.2rem] ml-[5.3rem]">
            <h1 className="text-indigoDarkGray text-[2.4rem] mb-[3.5rem] font-bold ">
              Ai ales urmatorul terapeut
            </h1>
            <div className="border-b-lightGray border-b mb`-[3.3rem]" />
            <div className="flex mt-[2rem]">
            <p className="text-veryDarkPurple text-[1.4rem] mb-[3.3rem] mr-[0.5rem]">{currentTherapist?.user.externalUser?.firstName}</p>
            <p className="text-veryDarkPurple text-[1.4rem] mb-[3.3rem]">{currentTherapist?.user.externalUser?.lastName}</p>
            </div>
            <p className="text-veryDarkPurple text-[1.4rem]">
              Vei fi contactat de către psiholog pentru a stabili pașii următori …
            </p>

            <div class="flex justify-around mt-[11rem] mb-[4rem]">
              <button
                onClick={() => setOpened(false)}
                className="uppercase border-indigoDarkGray border rounded-xl text-indigoDarkGray w-[20rem] h-[4.8rem] text-[1.2rem]">
                renunta
              </button>
              <button
                onClick={handleSubmit}
                className="uppercase bg-indigoDarkGray border rounded-xl text-veryDarkWhite w-[20rem] h-[4.8rem] text-[1.2rem]">
                salveaza
              </button>
            </div>
          </div>
        }
      </Modal>
    </Paper>
  );
};

export default Therapists;
