export const COLORS = {
  veryDarkWhite: '#feffff',
  guyabano: '#F8F8F8',
  christmasSilver: '#E1E1E1',
  lightGray: '#C4C4C4',
  red: '#FF0000',
  veryLightPurple: '#EEEEEE',
  genericPink: '#FFC4C4',
  genericBlue: '#0058FF',
  indigoDarkGray: '#233975',
  genericGray: '#7E84A3',
  veryDarkPurple: '#171725'
};
