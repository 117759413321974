import React, { useEffect } from 'react';
import { Button, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import { EyeCheck, EyeOff } from 'tabler-icons-react';
import './Login.scss';
import {useForm, yupResolver} from '@mantine/form';
import { useCookies } from 'react-cookie';
import httpClient from '../../components/httpClient';
import { NavLink, useNavigate } from 'react-router-dom';
import { COLORS } from '../../values/colors';
import { addUser, removerUser } from '../../redux/user-slice';
import { useDispatch } from 'react-redux';
import { removeAllAssessments } from '../../redux/assessment-slice';
import { removeCookie } from '../../features/cookie';
import * as Yup from "yup";

const ForgotPassword = () => {
  const schema = Yup.object().shape({
    email: Yup.string().email('Formatul de e-mail este invalid').required('E-mail-ul este obligatoriu'),
  })

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      email: '',
    },
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email')
    }
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    removeCookie('access_token');
    removeCookie('refresh_token');
    dispatch(removerUser());
    dispatch(removeAllAssessments());
  }, []);

  const submitLoginData = ({ email }) => {
    httpClient({
      method: 'POST',
      url: '/v1/users/forgot-password-reset',
      data: {
        email
      }
    }).then(() => {
      navigate('/login');
    });
  };

  return (
    <div className="pageContainer">
      <div className="leftSideContainer">
        <Text className="leftSideTitle" weight="bold" color={COLORS.veryDarkWhite} align="center">
          Evaluare și diagnostic gratuit
        </Text>
        <Text weight={600} color={COLORS.veryDarkWhite} align="center" className="leftSideSubtitle">
          Intră și fă-ți un test de autoevaluare pentru depresie, anxietate, stres
        </Text>
      </div>
      <div className="rightSideContainer">
        <div className="rightSideTextGroup">
          <Text align="center" weight="bold" className="rightSideTitle">
            Schimbă parola
          </Text>
          <Group spacing="xs">
            <Text className="changePasswordText">Nu ai un cont?</Text>
            <NavLink
              to="/signup"
              style={{
                textDecoration: 'none'
              }}>
              <Text className="changePasswordText" weight={600} color={COLORS.indigoDarkGray}>
                Creează cont
              </Text>
            </NavLink>
          </Group>
        </div>
        <form onSubmit={form.onSubmit((values) => submitLoginData(values))}>
          <Text className="emailLabel">Email</Text>
          <TextInput
            placeholder="Email"
            size="md"
            radius="md"
            type="email"
            className="emailInput"
            {...form.getInputProps('email')}
          />
          <Group spacing="xs" style={{ marginTop: '3rem' }}></Group>
          <Button
            type="submit"
            className="loginButton"
            style={{ backgroundColor: COLORS.indigoDarkGray }}
            uppercase
            radius="md">
            Trimitere mail pentru resetare
          </Button>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
