import React, {useState} from 'react';
import OnboardingLeftPanel from '../../components/onboarding/OnboardingLeftPanel';
import { Button, Checkbox, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import OnboardingContainer from '../../components/onboarding/OnboardingContainer';
import OnboardingRightContainer from '../../components/onboarding/OnboardingRightContainer';
import { EyeCheck, EyeOff } from 'tabler-icons-react';
import { useForm } from '@mantine/form';
import '../onboarding/Login.scss';
import { NavLink, useNavigate } from 'react-router-dom';
import httpClient from '../../components/httpClient';
import { removeCookie } from '../../features/cookie';
import { useCookies } from 'react-cookie';
import { COLORS } from '../../values/colors';
import { getUser, removerUser } from '../../redux/user-slice';
import { useDispatch } from 'react-redux';
import { removeAllAssessments } from '../../redux/assessment-slice';
import * as Yup from "yup";
import {yupResolver} from "@mantine/form";

const TherapistSignup = () => {
  const schema = Yup.object().shape({
    username: Yup.string().email('Formatul de e-mail este invalid').required('E-mail-ul este obligatoriu'),
    password:  Yup.string().min(6, "Cel putin 6 caractere sunt necesare").required('Parola este obligatorie'),
    firstName: Yup.string().required('Prenumele este obligatoriu'),
    lastName: Yup.string().required('Numele este obligatoriu'),
    token: Yup.string().required('Token-ul este obligatoriu'),
  })

  const form = useForm({
    schema: yupResolver(schema),
    initialValues: {
      username: '',
      password: '',
      firstName: '',
      lastName: '',
      token: ''
    }
  });

  const [, setTokenCookie] = useCookies(['token']);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const doSignUp = ({ username, password, firstName, lastName, token }) => {
    removeCookie('access_token');
    removeCookie('refresh_token');
    dispatch(removerUser());
    dispatch(removeAllAssessments());

    httpClient
      .post('v1/auth/therapist-signup', {
        username: username,
        password: password,
        firstName: firstName,
        lastName: lastName,
        token: token
      })
      .then((res) => {
        const loginFormData = new FormData();
        loginFormData.append('username', username);
        loginFormData.append('password', password);

        httpClient
          .post('secureLogin', loginFormData, {
            crossDomain: true,
            headers: {
              'Content-Type': 'application/form-data',
              'Access-Control-Allow-Origin': 'http://localhost:3000',
              Accept: 'application/json'
            }
          })
          .then((response) => {
            setTokenCookie('access_token', response.headers.access_token, {
              path: '/'
            });
            setTokenCookie('refresh_token', response.headers.refresh_token, {
              path: '/'
            });
            dispatch(getUser({ username })).then(() => navigate('/', { replace: true }));
          })
          .catch((e) => {
            console.log(e)
          });
      }).catch(e => {
      if (e.response.data.message === "Token not matching") {
        setError("Tokenul nu este valid");
      }
    })
    ;
  };

  return (
    <OnboardingContainer>
      <OnboardingLeftPanel />
      <OnboardingRightContainer>
        <div className="rightSideTextGroup">
          <Text align="center" weight="bold" className="rightSideTitle">
            Creeaza cont
          </Text>
          <Group spacing="xs">
            <Text className="changePasswordText">Ai deja un cont?</Text>
            <NavLink
              to="/login"
              style={{
                textDecoration: 'none'
              }}>
              <Text className="changePasswordText" weight={600} color={COLORS.indigoDarkGray}>
                Log In
              </Text>
            </NavLink>
          </Group>
        </div>
        <form onSubmit={form.onSubmit((values) => doSignUp(values))}>
          <Text className="emailLabel">Nume</Text>
          <TextInput
            placeholder="Nume"
            size="md"
            radius="md"
            className="emailInput"
            {...form.getInputProps('lastName')}
          />
          <Text className="passwordLabel">Prenume</Text>
          <TextInput
            className="emailInput"
            placeholder="Prenume"
            size="md"
            radius="md"
            {...form.getInputProps('firstName')}
          />
          <Text className="passwordLabel">Email</Text>
          <TextInput
            placeholder="Email"
            size="md"
            radius="md"
            className="emailInput"
            {...form.getInputProps('username')}
          />
          <Text className="passwordLabel">Parolă</Text>
          <PasswordInput
            placeholder="Parolă (minim 6 caractere)"
            className="emailInput"
            size="md"
            radius="md"
            {...form.getInputProps('password')}
            visibilityToggleIcon={({ reveal, size }) =>
              reveal ? <EyeOff size={size} /> : <EyeCheck size={size} />
            }
          />
          <Text className="passwordLabel">Cod validare email</Text>
          <TextInput
            placeholder="Cod validare"
            size="md"
            radius="md"
            className="emailInput"
            {...form.getInputProps('token')}
          />
          {error && <Text className="text-[1.2rem] mt-[2rem] text-red">{error}</Text>}
          <Checkbox
            styles={{
              label: {
                color: COLORS.veryDarkPurple,
                fontSize: '1.2rem',
                fontWeight: 500,
                width: '28rem'
              }
            }}
            label="Dorești să primești notificări cu privire la servicii și alte informații de la depreHUB"
            mt="lg"
            mb="lg"
          />
          <Button
            type="submit"
            className="loginButton"
            style={{ backgroundColor: COLORS.indigoDarkGray }}
            uppercase
            radius="md">
            Înregistrează-te
          </Button>
        </form>
        <Text
          align="center"
          style={{ width: '33rem', fontSize: '1.2rem' }}
          mt="lg"
          color={COLORS.veryDarkPurple}>
          Continuând, sunteți de acord să acceptați Politica de confidențialitate și Termenii și
          condițiile.
        </Text>
      </OnboardingRightContainer>
    </OnboardingContainer>
  );
};

export default TherapistSignup;
