import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import { Group, Text } from '@mantine/core';
import { COLORS } from '../values/colors';

const CustomNavLink = ({ icon, children, to, rel, target, ...props }) => {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: true });
  return (
    <div
      style={{
        backgroundColor: match ? 'rgba(0, 98, 255, 0.05)' : 'rgba(0, 98, 255, 0)'
      }}>
      <Link
          target={target}
          rel={rel}
        style={{
          weight: 500,
          textDecoration: 'none',
          color: match ? '#0062ff' : COLORS.veryDarkPurple,
        }}
        to={to}
        {...props}>
        <Group
          spacing="md"
          p="md"
          align="center"
          style={{
            borderLeft: match ? `0.4rem solid ${COLORS.genericBlue}` : '0.4rem solid transparent'
          }}>
          {icon}
          <Text style={{ fontSize: '1.4rem' }}>{children}</Text>
        </Group>
      </Link>
    </div>
  );
};

export default CustomNavLink;
