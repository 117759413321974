import { AppShell, Header, Navbar, Stack } from '@mantine/core';
import React from 'react';
import CustomNavLink from './CustomNavLink';
import { User } from './user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightFromBracket,
  faBookOpenReader,
  faFile,
  faHome,
  faUser,
  faUserDoctor,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
import { faFile as ffFile } from '@fortawesome/free-regular-svg-icons';
import { COLORS } from '../values/colors';
import {  useSelector } from 'react-redux';
import { currentUser } from '../redux/user-slice';
import { useNavigate } from 'react-router-dom';

const IconNavLink = ({ icon, name, path, rel, target }) => {
  return (
    <CustomNavLink icon={icon} to={path} rel={rel} target={target}>
      {name}
    </CustomNavLink>
  );
};

const AppHeader = () => (
  <Header height={60} p="xs">
    {<div>depreHUB - 2022</div>}
  </Header>
);

export const LayoutScreen = ({ children }) => {
  const user = useSelector(currentUser);
  //TODO: Switch to role_ADMIN
  const isUser = user?.roles?.find((role) => role.name === 'ROLE_USER');
  const isAdmin = user?.roles?.find((role) => role.name === 'ROLE_ADMIN');
  const isTherapist = user?.roles?.find((role) => role.name === 'ROLE_THERAPIST');
  const navigate = useNavigate();

  return (
    <AppShell
      fixed
      style={{ backgroundColor: COLORS.veryLightPurple }}
      header={<AppHeader />}
      navbar={
        <Navbar width={{ base: 270 }} p="xs">
          <Stack spacing="xs">
            <IconNavLink
              icon={<FontAwesomeIcon icon={faHome} fontSize={17} width={20} />}
              name="Acasa"
              path="/"
            />
            {isUser && !isAdmin && (
              <>
                <IconNavLink
                  icon={<FontAwesomeIcon icon={faUserDoctor} fontSize={17} width={20} />}
                  name="Alege Terapeut"
                  path="/therapists"
                />
                <IconNavLink
                  icon={<FontAwesomeIcon icon={ffFile} fontSize={17} width={20} />}
                  name="Lista teste"
                  path="/assessment"
                />
                <IconNavLink
                  icon={<FontAwesomeIcon icon={faFile} fontSize={17} width={20} />}
                  name="Rapoarte"
                  path="/raports"
                />
              </>
            )}

            <IconNavLink
              icon={<FontAwesomeIcon icon={faUser} fontSize={17} width={20} />}
              name="Profil"
              path="/profile"
            />

            <IconNavLink
              icon={<FontAwesomeIcon icon={faBookOpenReader} fontSize={17} width={20} />}
              rel="noopener noreferrer"
              target="_blank"
              name="Resurse"
              path="/resurse"
            />

            {isAdmin && (
              <>
                <IconNavLink
                  icon={<FontAwesomeIcon icon={faUsers} fontSize={17} width={20} />}
                  name="Utilizatori"
                  path="/admin/users"
                />
              </>
            )}
            {isTherapist && !isAdmin &&  (
              <>
                <IconNavLink
                  icon={<FontAwesomeIcon icon={faUsers} fontSize={17} width={20} />}
                  name="Pacienti"
                  path="/therapist/users"
                />
              </>
            )}
            <IconNavLink
              icon={<FontAwesomeIcon icon={faArrowRightFromBracket} fontSize={17} width={20} />}
              name="Logout"
              path="/login"
            />
          </Stack>
          <Navbar.Section onClick={() => {navigate('/profile')}}>
            <User
              username={user.username}
              name={`${user?.externalUser?.firstName} ${user?.externalUser?.lastName}`}
            />
          </Navbar.Section>
        </Navbar>
      }>
      <div>{children}</div>
    </AppShell>
  );
};
