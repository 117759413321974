import React, { useEffect } from 'react';
import { Button, Group, PasswordInput, Text, TextInput } from '@mantine/core';
import './Login.scss';
import {useForm, yupResolver} from '@mantine/form';
import httpClient from '../../components/httpClient';
import {NavLink, useNavigate, useParams} from 'react-router-dom';
import { COLORS } from '../../values/colors';
import { removerUser } from '../../redux/user-slice';
import { useDispatch } from 'react-redux';
import { removeAllAssessments } from '../../redux/assessment-slice';
import { removeCookie } from '../../features/cookie';
import {EyeCheck, EyeOff} from "tabler-icons-react";
import * as Yup from "yup";

const ForgotPasswordReset = () => {
    const schema = Yup.object().shape({
        password:  Yup.string().min(6, "Cel putin 6 caractere sunt necesare").required('Parola este obligatorie'),
    })


    const form = useForm({
        schema: yupResolver(schema),
        initialValues: {
            password: '',
        },
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useParams();

    useEffect(() => {
        removeCookie('access_token');
        removeCookie('refresh_token');
        dispatch(removerUser());
        dispatch(removeAllAssessments());
    }, []);

    const submitLoginData = ({ password }) => {
        httpClient({
            method: 'post',
            url: '/v1/users/forgot-password-token-auth',
            data: {
                token: token,
                password: password,
            }
        }).then(() => {
            navigate('/login');
        })
    };

    return (
        <div className="pageContainer">
            <div className="leftSideContainer">
                <Text className="leftSideTitle" weight="bold" color={COLORS.veryDarkWhite} align="center">
                    Evaluare și diagnostic gratuit
                </Text>
                <Text weight={600} color={COLORS.veryDarkWhite} align="center" className="leftSideSubtitle">
                    Intră și fă-ți un test de autoevaluare pentru depresie, anxietate, stres
                </Text>
            </div>
            <div className="rightSideContainer">
                <div className="rightSideTextGroup">
                    <Text align="center" weight="bold" className="rightSideTitle">
                        Schimbă parola
                    </Text>
                    <Group spacing="xs">
                        <Text className="changePasswordText">Nu ai un cont?</Text>
                        <NavLink
                            to="/signup"
                            style={{
                                textDecoration: 'none'
                            }}>
                            <Text className="changePasswordText" weight={600} color={COLORS.indigoDarkGray}>
                                Creează cont
                            </Text>
                        </NavLink>
                    </Group>
                </div>
                <form onSubmit={form.onSubmit((values) => submitLoginData(values))}>
                    <Text className="emailLabel">Parola noua</Text>
                    <PasswordInput
                        placeholder="Parolă"
                        className="passwordInput"
                        size="md"
                        radius="md"
                        {...form.getInputProps('password')}
                        visibilityToggleIcon={({ reveal, size }) =>
                            reveal ? <EyeOff size={size} /> : <EyeCheck size={size} />
                        }
                    />
                    <Group spacing="xs" style={{ marginTop: '3rem' }}></Group>
                    <Button
                        type="submit"
                        className="loginButton"
                        style={{ backgroundColor: COLORS.indigoDarkGray }}
                        uppercase
                        radius="md">
                        Reseteaza parola
                    </Button>
                </form>
            </div>
        </div>
    );
};

export default ForgotPasswordReset;
