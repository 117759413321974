import './App.css';
import Login from './pages/onboarding/Login';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { LayoutScreen } from './components/LayoutScreen';
import ProtectedRoute from './components/ProtectedRoute';
import Dashboard from './pages/Dashboard';
import TestLists from './pages/TestLists';
import SignUp from './pages/SignUp';
import Assessment from './pages/Assessment';
import Profile from './pages/Profile';
import Therapists from './pages/Therapists';
import Resources from './pages/Resources';
import { Users } from './pages/admin/Users';
import { AssessmentRaport } from './pages/AssessmentRaport';
import UserInfo from './pages/therapists/UserInfo';
import TherapistUsers from './pages/therapists/TherapistUsers';
import TherapistSignup from './pages/therapists/TherapistSignup';
import ForgotPassword from './pages/onboarding/ForgotPassword';
import ForgotPasswordReset from './pages/onboarding/ForgotPasswordReset';
import UserVerification from './pages/onboarding/UserVerification';
import EmailPending from './pages/onboarding/EmailPending';
import {NotificationsProvider} from "@mantine/notifications";

const App = () => (
  <MantineProvider
    theme={{
      fontFamily: 'Montserrat'
    }}>
    <NotificationsProvider zIndex={5055}>
      <ModalsProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password/token=:token" element={<ForgotPasswordReset />} />
            <Route path="/therapist-signup" element={<TherapistSignup />} />
            <Route
              path="/"
              element={
                <ProtectedRoute allowedRoles={['ROLE_USER', 'ROLE_THERAPIST']}>
                  <LayoutScreen>
                    <Dashboard />
                  </LayoutScreen>
                </ProtectedRoute>
              }
            />
            <Route
              path="/assessment"
              element={
                <ProtectedRoute allowedRoles={['ROLE_USER']}>
                  <LayoutScreen>
                    <TestLists />
                  </LayoutScreen>
                </ProtectedRoute>
              }
            />
            <Route
              path="/assessment/:id/:versionID"
              element={
                <ProtectedRoute allowedRoles={['ROLE_USER']}>
                  <LayoutScreen>
                    <Assessment />
                  </LayoutScreen>
                </ProtectedRoute>
              }
            />
            <Route
              path="/profile"
              element={
                <ProtectedRoute allowedRoles={['ROLE_USER', 'ROLE_THERAPIST']}>
                  <LayoutScreen>
                    <Profile />
                  </LayoutScreen>
                </ProtectedRoute>
              }
            />
            <Route
              path="/therapists"
              element={
                <ProtectedRoute allowedRoles={['ROLE_USER']}>
                  <LayoutScreen>
                    <Therapists />
                  </LayoutScreen>
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin/users"
              element={
                <ProtectedRoute allowedRoles={['ROLE_ADMIN', 'ROLE_THERAPIST']}>
                  <LayoutScreen>
                    <Users />
                  </LayoutScreen>
                </ProtectedRoute>
              }
            />
            <Route path="/resurse" element={<Resources />} />
            <Route
              path="/raports"
              element={
                <ProtectedRoute allowedRoles={['ROLE_USER', 'ROLE_THERAPIST', 'ROLE_ADMIN']}>
                  <LayoutScreen>
                    <AssessmentRaport />
                  </LayoutScreen>
                </ProtectedRoute>
              }
            />
            <Route
              path="/therapist/users"
              element={
                <ProtectedRoute allowedRoles={['ROLE_THERAPIST']}>
                  <LayoutScreen>
                    <TherapistUsers />
                  </LayoutScreen>
                </ProtectedRoute>
              }
            />
            <Route path="/therapist/users/:id" element={<UserInfo />} />
            <Route path="/email-confirmation/:token" element={<UserVerification />} />
            <Route path="/email-confirmation" element={<EmailPending />} />
          </Routes>
        </BrowserRouter>
      </ModalsProvider>
    </NotificationsProvider>
  </MantineProvider>
);

export default App;
