import React, {useEffect} from 'react';

const Resources = () => {
  useEffect(() => {
    window.location.replace("https://deprehub.ro/psihoeducatie/")
  }, []);
  return <div></div>;
};

export default Resources;
