import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import httpClient from '../components/httpClient';
import { useEffect } from 'react';

export const startEvaluation = createAsyncThunk(
  'assessments/startEvaluation',
  async ({ questionnaireVersionID, personID, pageID, username }) => {
    const response = await httpClient({
      method: 'post',
      url: 'v1/external/startEvaluation',
      data: {
        questionnaireVersionID: questionnaireVersionID,
        username: username,
        personID: personID,
        assessmentId: pageID
      }
    });
    return { data: response.data, pageID };
  }
);

export const getEvaluation = createAsyncThunk(
  'assessments/getEvaluation',
  async ({ accessKey, pageID }) => {
    console.log(accessKey);
    const response = await httpClient({
      method: 'post',
      url: 'v1/external/getEvaluationResponse',
      data: {
        accessKey: accessKey
      }
    });
    return { data: response.data, pageID };
  }
);

export const answerEvaluation = createAsyncThunk(
  'assessments/answerEvaluation',
  async ({ accessKey, questionId, responseOptionId, pageID }) => {
    const response = await httpClient({
      method: 'post',
      url: 'v1/external/answerEvaluation',
      data: {
        accessKey: accessKey,
        questionId: questionId,
        responseOptionId: responseOptionId
      }
    });
    return { data: response.data, pageID };
  }
);

export const cancelAssessment = createAsyncThunk(
  'assessments/cancelAssessment',
  async ({ pageID, id }) => {
    const response = await httpClient({
      method: 'post',
      url: 'v1/external/cancelEvaluation',
      data: {
        id: id
      }
    }).then(() => {
      return { pageID };
    });
    return response;
  }
);

export const removeAssessment = createAsyncThunk(
  'assessments/removeAssessment',
  async ({ pageID }) => {
    return { pageID };
  }
);

export const setHasStarted = createAsyncThunk(
  'assessments/setHasStarted',
  async ({ pageID, hasStarted }) => {
    return { pageID, hasStarted };
  }
);

export const getAssessments = createAsyncThunk('assessments/getAssessments', async () => {
  const response = await httpClient({
    method: 'get',
    url: 'v1/external/assessments'
  });
  return { data: response.data };
});

const assessmentsSlice = createSlice({
  name: 'assessments',
  initialState: {
    startedEvaluations: {},
    assessmentsList: { status: '' },
    newAssessments: [{ pageID: -1, accessKey: '', data: {}, status: '' }]
  },
  reducers: {
    removeAllAssessments: (state) => {
      state.startedEvaluations = {};
      state.assessmentsList = {};
      state.newAssessments = [{}];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(startEvaluation.fulfilled, (state, { payload: { data, pageID } }) => {
      if (!state.startedEvaluations[pageID]) {
        state.startedEvaluations[pageID] = { data: {} };
        state.startedEvaluations[pageID] = { ...data };
        if (!state?.newAssessments.find((item) => item?.pageID === pageID)) {
          state.newAssessments.push({
            data: {},
            pageID,
            accessKey: data.accessKey,
            status: 'current'
          });
        }
      }
      state.status = 'loaded';
    });
    builder.addCase(getEvaluation.fulfilled, (state, { payload: { data, pageID } }) => {
      if (pageID) {
        state.startedEvaluations[pageID].data = data;
        const foundAssessmentIdx = state?.newAssessments?.findIndex(
          (item) => item?.pageID === pageID && item?.status === 'current'
        );
        if (foundAssessmentIdx !== -1) {
          state.newAssessments[foundAssessmentIdx].data = data;
        }
      }
      state.status = 'loaded';
    });
    builder.addCase(removeAssessment.fulfilled, (state, { payload: { pageID } }) => {
      const foundAssessmentIdx = state?.newAssessments?.findIndex(
        (item) => item?.pageID === pageID && item?.status === 'current'
      );
      if (foundAssessmentIdx !== -1) {
        delete state.newAssessments[foundAssessmentIdx];
      }
      delete state.startedEvaluations[pageID];
      state.status = 'loaded';
    });
    builder.addCase(cancelAssessment.fulfilled, (state, { payload: { pageID } }) => {
      const foundAssessmentIdx = state?.newAssessments?.findIndex(
        (item) => item?.pageID === pageID && item?.status === 'current'
      );
      if (foundAssessmentIdx !== -1) {
        delete state.newAssessments[foundAssessmentIdx];
      }
      delete state.startedEvaluations[pageID];
      state.status = 'loaded';
    });
    builder.addCase(setHasStarted.fulfilled, (state, { payload: { pageID, hasStarted } }) => {
      if (pageID) {
        state.startedEvaluations[pageID].hasStarted = hasStarted;
      }
    });
    builder.addCase(getAssessments.fulfilled, (state, { payload: { data } }) => {
      state.assessmentsList.data = [...data];
      state.assessmentsList.status = 'loaded';
    });
  }
});

export const { removeAllAssessments } = assessmentsSlice.actions;
export const currentAssessment = (state) => state.assessments.startedEvaluations;
export const assessmentsList = (state) => state.assessments.assessmentsList;
export default assessmentsSlice.reducer;
