import { Divider, Modal, Paper, Tabs, Text } from '@mantine/core';
import RichTextEditor from '@mantine/rte';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import httpClient from '../../components/httpClient';
import { currentUser, getUser } from '../../redux/user-slice';
import { COLORS } from '../../values/colors';
import { Raport } from '../AssessmentRaport';

const UserInfo = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const user = useSelector(currentUser);
  const [currentPatient, setCurrentPatient] = useState({});
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [displayedOnRichTextEditor, setDisplayedOnRichTextEditor] = useState('');
  const [noteTargetId, setNoteTargetId] = useState(-1);

  useEffect(() => {
    dispatch(getUser({ username: user?.username }));
  }, []);

  const handleNoteChange = (evaluation) => {
    if (currentPatient) {
      setNoteTargetId(evaluation?.id);
      setDisplayedOnRichTextEditor(evaluation?.note?.text);
      setModalOpen(!modalOpen);
    }
  };

  const setPatientNote = (val) => {
    if (noteTargetId) {
      // look into previous state and update the note text for the patient that id is equal to evId with val
      setCurrentPatient({
        ...currentPatient,
        evaluations: currentPatient.evaluations.map((evaluation) => {
          if (evaluation.id === noteTargetId) {
            return {
              ...evaluation,
              note: {
                ...evaluation.note,
                text: val
              }
            };
          }
          return evaluation;
        })
      });
    }
    setDisplayedOnRichTextEditor(val);
  };

  useEffect(() => {
    setCurrentPatient(user?.therapist?.userList.find((patient) => patient.id === Number(id)));
  }, [id, user?.therapistList?.userList]);

  return (
    <div className="bg-veryLightPurple">
      <header className="bg-veryDarkWhite h-[6rem] border-b border-b-veryLightPurple">
        <p>depreHUB 2022</p>
      </header>
      <div className="flex">
        <div className="bg-veryDarkWhite w-[35rem] min-h-[93vh] px-[2.2rem] ">
          <div className="pt-[2.2rem] mb-[2.8rem]">
            <a
              className=" text-indigoDarkGray text-[1.6rem] font-bold hover:text-genericBlue"
              onClick={() => navigate('/therapist/users')}>
              {'<'} Inapoi
            </a>
          </div>
          <div className="border-b-veryLightPurple border-b pb-[2.4rem] mb-[1rem]">
            <p className="text-[2.2rem] text-indigoDarkGray font-medium">Pacient</p>
            <p className="text-[2rem]">
              {currentPatient?.externalUser?.firstName} {currentPatient?.externalUser?.lastName}
            </p>
            <span className="text-[1.4rem] ">{currentPatient.username}</span>
          </div>
          <p className="text-[1.4rem] font-medium">Date despre contact</p>
          <div className="text-[1.2rem]">
            <p className="text-indigoDarkGray text-[1rem] mt-[1.2rem]">Email</p>
            <p>{currentPatient?.username}</p>
            <p className="text-indigoDarkGray text-[1rem] mt-[1.2rem]">Telefon</p>
            <p>{currentPatient?.externalUser?.phoneNumber || 'Nu exista telefon inregistrat'}</p>
            <p className="text-indigoDarkGray text-[1rem] mt-[1.2rem]">Psiholog</p>
            <span>
              {user?.externalUser?.firstName} {user?.externalUser?.lastName}
            </span>
            <p className="text-indigoDarkGray text-[1rem] mt-[1.2rem]">Ultima interactiune</p>
            <span>{new Date(currentPatient?.updatedAt).toLocaleString()}</span>
            <p className="text-indigoDarkGray text-[1rem] mt-[1.2rem]">Numar total de evaluari</p>
            <span>{currentPatient?.evaluations?.length}</span>
            <p className="text-indigoDarkGray text-[1rem] mt-[1.2rem]">Evaluari complete</p>
            <span>
              {
                currentPatient?.evaluations?.filter(
                  (evaluation) => evaluation?.status === 'completed'
                )?.length
              }
            </span>
          </div>
        </div>
        <div>
          <Tabs
            styles={{
              tabActive: {
                fontWeight: 500,
                color: COLORS.indigoDarkGray,
                fontSize: '1.4rem'
              }
            }}
            variant="default"
            className="mx-[1.6rem] my-[2rem]">
            <Tabs.Tab label="Activitate">
              <Paper className="w-[111.4rem] h-[15rem]">text</Paper>
            </Tabs.Tab>
            <Tabs.Tab label="Rapoarte">
              <div>
                {currentPatient?.evaluations?.map(
                  (evaluation) =>
                    evaluation?.status === 'completed' && (
                      <Paper
                        style={{
                          width: '100vh',
                          padding: '2rem',
                          marginTop: '1rem',
                          minHeight: '5rem'
                        }}>
                        <Text
                          onClick={() => handleNoteChange(evaluation)}
                          className="header"
                          size="sm"
                          weight="bold"
                          mb="md">
                          {evaluation?.assessment.externalAssessments?.name} -{' '}
                          {new Date(evaluation?.updatedAt).toLocaleString()}
                        </Text>
                        <Modal size={'120rem'} opened={modalOpen} centered onClose={setModalOpen}>
                          <div>
                            <Text className="header text-[3rem]" size="sm" weight="bold" mb="md">
                              {evaluation?.assessment.externalAssessments?.name} -{' '}
                              {new Date(evaluation?.updatedAt).toLocaleString()}
                            </Text>
                            <RichTextEditor
                              value={displayedOnRichTextEditor}
                              onChange={(val) => setPatientNote(val, evaluation.id)}
                              controls={[
                                ['bold', 'italic', 'underline', 'link'],
                                ['unorderedList', 'orderedList', 'h1', 'h2', 'h3']
                              ]}
                            />
                            <button
                              className=" bg-indigoDarkGray text-veryDarkWhite rounded-md h-[4rem] w-[12rem] mt-[2rem] text-[1.2rem] font-bold my-[4rem]"
                              onClick={() => {
                                console.log(evaluation);
                                httpClient({
                                  method: 'post',
                                  url: 'v1/external/addNote',
                                  data: {
                                    evaluationId: noteTargetId,
                                    text: displayedOnRichTextEditor
                                  }
                                })
                              }}>
                              TRIMITE
                            </button>
                            <Raport evaluationReport={evaluation?.raport} />
                          </div>
                        </Modal>
                      </Paper>
                    )
                )}
              </div>
            </Tabs.Tab>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
export default UserInfo;
