import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Group,
  List,
  Loader,
  Modal,
  Paper,
  Select,
  Space,
  Text
} from '@mantine/core';
import httpClient from '../components/httpClient';
import { faFile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatePicker } from '@mantine/dates';
import { COLORS } from '../values/colors';
import 'dayjs/locale/ro';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, currentUser } from '../redux/user-slice';
import { FormattedText } from '../components/FormattedText';
import {assessmentsList, getAssessments} from "../redux/assessment-slice";
import dayjs from "dayjs";

const TestLists = () => {
  const assessments = useSelector(assessmentsList);
  const user = useSelector(currentUser);
  const dispatch = useDispatch();
  const [birthDate, setBirthDate] = useState(new Date(user?.externalUser?.birthDate) || new Date(2-2-1992));
  const [sex, setSex] = useState('');
  const [isModalOpen, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const handleModal = (id, versionID) => {
    // verify if externalData exists
    const noBirthdateOrSex = !user?.externalUser?.birthDate || !user?.externalUser?.sex;
    if (noBirthdateOrSex) {
      return setOpenModal(true);
    }
    // if modal is !open ->
    if (!isModalOpen) navigate(`/assessment/${id}/${versionID}`);
  };

  const patchExternalUserData = () => {
    httpClient
      .patch(`v1/users/${user.username}`, {
        birthDate,
        sex
      })
      .then((response) => {
        dispatch(addUser(response.data));
        setOpenModal(false);
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    dispatch(getAssessments());
  }, []);

  const MouseOver = (event) => {
    event.target.style.cursor = 'pointer';
    event.target.style.backgroundColor = 'rgba(0, 98, 255, 0.05)';
  };

  const MouseLeave = (event) => {
    event.target.style.cursor = 'default';
    event.target.style.backgroundColor = 'white';
  };

  const renderComponent = () => {
    if (assessments.status !== "loaded") {
      return <Loader size={25} />;
    } else {
      return (
        <>
          <Text color={COLORS.indigoDarkGray} size="sm" weight="bold" mt="md">
            Lista chestionare
          </Text>
          <Divider my="xs" />
          <List
            size="md"
            icon={
              <FontAwesomeIcon
                color={COLORS.veryDarkPurple}
                icon={faFile}
                fontSize={17}
                width={20}
              />
            }>
            {assessments?.data.map((assessment) => (
              <List.Item
                key={assessment.id}
                pb="xs"
                onClick={() => handleModal(assessment.id, assessment.externalAssessments?.versions[0].id)}>
                <Text
                  color={COLORS.veryDarkPurple}
                  size="md"
                  onMouseOver={MouseOver}
                  onMouseLeave={MouseLeave}>
                  {FormattedText(assessment?.externalAssessments?.name)}: {FormattedText(assessment?.externalAssessments?.shortDescription)}
                </Text>
              </List.Item>
            ))}
          </List>
        </>
      );
    }
  };

  return (
    <Paper shadow="md" p="md" radius="xs">
      <>
        <Modal
          centered
          withCloseButton={false}
          opened={isModalOpen}
          onClose={() => setOpenModal(false)}
          size="30%">
          <>
            <Text color={COLORS.indigoDarkGray} size="sm" weight="bold" mt="md">
              Atentie!
            </Text>
            <Divider my="xs" />
            <Text color={COLORS.veryDarkPurple}>
              Se pare ca ai niste date necompletate, te rog completeaza urmatorul formular pentru a
              putea incepe testarea:
            </Text>
            <Space h="md" />
            <Box sx={{ maxWidth: 300 }}>
              <Select
                label="Sex"
                placeholder="M/F"
                value={sex}
                onChange={setSex}
                required
                sx={{ label: { color: COLORS.veryDarkPurple } }}
                data={[
                  { value: 'M', label: 'Masculin' },
                  { value: 'F', label: 'Feminin' }
                ]}
              />
              <DatePicker
                sx={{ label: { color: COLORS.veryDarkPurple } }}
                placeholder="Data nasterii"
                label="Data nasterii"
                dropdownType="modal"
                required
                locale="ro"
                mt="md"
                maxDate={dayjs(new Date()).endOf('year').subtract(10, 'years').toDate()}
                value={birthDate}
                onChange={setBirthDate}
              />
            </Box>
            <div>
              <button
                class="uppercase bg-indigoDarkGray text-veryDarkWhite h-[3.5rem] w-[8rem] border rounded-lg mt-[4rem]"
                onClick={() => patchExternalUserData()}>
                trimite
              </button>
            </div>
          </>
        </Modal>
        {renderComponent()}
      </>
    </Paper>
  );
};

export default TestLists;
