import cookie from 'cookie';

export function parseCookies(req) {
  const cookies = document.cookie;

  return cookie.parse(cookies);
}

export function removeCookie(cookieName) {
  document.cookie = `${cookieName}= ; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/`;
}
