import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { currentUser } from '../redux/user-slice';

const ProtectedRoute = ({ children, allowedRoles }) => {
  const location = useLocation();
  const user = useSelector(currentUser);
  return user?.roles?.find((role) => allowedRoles.includes(role.name))? children : (  <Navigate to={'/login'} state={{ from: location }} replace />)

};

export default ProtectedRoute;
