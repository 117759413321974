import React from 'react';
import { Text } from '@mantine/core';
import './OnboardingLeftPanel.scss';
import { COLORS } from '../../values/colors';

const OnboardingLeftPanel = () => {
  return (
    <div className="leftSideContainer">
      <Text className="leftSideTitle" weight="bold" color={COLORS.veryDarkWhite} align="center">
        Evaluare și diagnostic gratuit
      </Text>
      <Text weight={600} color={COLORS.veryDarkWhite} align="center" className="leftSideSubtitle">
        Intră și fă-ți un test de autoevaluare pentru depresie, anxietate, stres
      </Text>
    </div>
  );
};

export default OnboardingLeftPanel;
