import React, { useEffect } from 'react';
import { Divider, Group, Paper, Stack, Text } from '@mantine/core';
import { COLORS } from '../values/colors';
import { useDispatch, useSelector } from 'react-redux';
import { currentUser, getUser } from '../redux/user-slice';

const Dashboard = () => {
  const user = useSelector(currentUser);
  const updatedAt = new Date(user?.updatedAt);
  const createdAt = new Date(user?.createdAt);
  const dispatch = useDispatch();

  useEffect(() => {

    dispatch(getUser({ username: user?.username }));
  }, []);

  function order(a, b) {
    return a.createdAt < b.createdAt ? 1 : -1;
  }


  return (
    <Stack>
      <Paper shadow="md" p="md" radius="xs" sx={{ minHeight: '25vh' }}>
        <Group spacing="lg" mt="md" position="apart">
          <Text color={COLORS.indigoDarkGray} size="sm" weight="bold">
            Activitate
          </Text>
          <Text color={COLORS.veryDarkPurple} size="sm">
            Actualizat{' '}
            {updatedAt.toLocaleString('ro-RO', {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric'
            })}
          </Text>
        </Group>
        <Divider my="xs" />
        {
          //map through user.evaluations array and display only the one with user.evaluation.status === 'completed' sorted chronologically
          user.evaluations
            .map((evaluation) => {
              if (evaluation.status === 'completed') {
                return (
                  <Group
                    position="row"
                    spacing="lg"
                    sx={{ color: COLORS.indigoDarkGray }}
                    mb={'2rem'}>
                    <Text weight="bold" color={COLORS.indigoDarkGray} size="sm">
                      {new Date(evaluation?.updatedAt).toLocaleDateString('ro-RO', {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                      })}
                      :
                    </Text>
                    <Text color={COLORS.veryDarkPurple} size="sm">
                      {evaluation?.assessment?.internalAssessment?.name ||
                        evaluation?.assessment?.externalAssessments?.name}
                    </Text>
                  </Group>
                );
              }
            })
            .sort(order)
        }
        <Group position="row" spacing="lg" sx={{ color: COLORS.indigoDarkGray }}>
          <Text weight="bold" color={COLORS.indigoDarkGray} size="sm">
            {createdAt.toLocaleDateString('ro-RO', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
            :
          </Text>
          <Text color={COLORS.veryDarkPurple} size="sm">
            Inregistrare cont
          </Text>
        </Group>
      </Paper>
    </Stack>
  );
};

export default Dashboard;
